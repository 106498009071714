var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-radio-group',{staticClass:"appearance-selection",attrs:{"row":"","hide-details":""},model:{value:(_vm.activeAppearance),callback:function ($$v) {_vm.activeAppearance=$$v},expression:"activeAppearance"}},[(_vm.loading)?_vm._l((2),function(i){return _c('v-card',{key:("loading-card--" + i),staticClass:"appearance-card cy-card appearance-card--loading",attrs:{"outlined":"","flat":""}},[_c('span',{staticClass:"appearance-card__link"},[_c('AppearanceTile',{staticClass:"appearance-card__svg"})],1),_c('div',{staticClass:"appearance-card__selector"},[_c('v-progress-linear',{attrs:{"indeterminate":"","color":"grey"}})],1)])}):[_c('v-card',{key:"default-appearance",staticClass:"appearance-card cy-card",attrs:{"outlined":"","flat":"","disabled":_vm.isReadonly(_vm.defaultAppearance)}},[_c('span',{staticClass:"appearance-card__link",attrs:{"disabled":""}},[_c('AppearanceTile',{staticClass:"appearance-card__svg",style:({
            '--bg-color': _vm.getRGBColor(_vm.defaultAppearance).cssString,
            '--text-color': _vm.getRGBColor(_vm.defaultAppearance).contrastingColor.cssString,
          })}),_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" edit ")])],1),_c('div',{staticClass:"appearance-card__selector"},[_c('v-radio',{attrs:{"label":_vm.$t('Default'),"disabled":_vm.isReadonly(_vm.defaultAppearance),"readonly":_vm.isReadonly(_vm.defaultAppearance),"ripple":!_vm.isReadonly(_vm.defaultAppearance),"value":_vm.defaultAppearance.canonical,"color":"secondary"}})],1)]),_vm._l((_vm.customAppearances),function(appearance){return _c('v-card',{key:appearance.canonical,staticClass:"appearance-card cy-card",attrs:{"outlined":"","flat":"","disabled":_vm.isReadonly(appearance)}},[_c(_vm.isReadonly(appearance) ? 'span' : 'router-link',{tag:"router-link",staticClass:"appearance-card__link",attrs:{"to":{
          name: 'orgSettingsAppearance',
          params: { appearanceCanonical: appearance.canonical },
        },"disabled":_vm.isReadonly(appearance)}},[_c('AppearanceTile',{staticClass:"appearance-card__svg",style:({
            '--bg-color': _vm.getRGBColor(appearance).cssString,
            '--text-color': _vm.getRGBColor(appearance).contrastingColor.cssString,
          })}),_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" edit ")])],1),_c('div',{staticClass:"appearance-card__selector"},[_c('v-radio',{attrs:{"label":_vm._.upperFirst(appearance.canonical),"disabled":_vm.isReadonly(appearance),"readonly":_vm.isReadonly(appearance),"ripple":!_vm.isReadonly(appearance),"value":appearance.canonical,"color":"secondary"}})],1)],1)}),(_vm.canCreateCustomAppearance)?_c('v-card',{directives:[{name:"has-rights-to",rawName:"v-has-rights-to",value:('CreateAppearance'),expression:"'CreateAppearance'"}],staticClass:"appearance-card cy-card cy-card--dashed-border ml-3",attrs:{"flat":"","to":{ name: 'newOrgSettingsAppearance', params: { appearanceCanonical: 'custom' } }}},[_c('div',{staticClass:"add-custom"},[_c('span',{staticClass:"add-custom__text"},[_c('v-icon',{staticClass:"add-custom__icon"},[_vm._v("add")]),_vm._v(" "+_vm._s(_vm.$t('addCustom'))+" ")],1)])]):_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }