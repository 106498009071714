<template>
  <v-card class="cy-card cy-licence-info panel panel--with-border">
    <div
      v-if="hasLicence"
      class="cy-licence-info__members-count">
      <div class="d-flex justify-space-between">
        <section class="d-flex flex-column">
          <label class="label mb-1">Current plan</label>
          <CyTag>{{ planName }}</CyTag>
        </section>

        <section>
          <label
            class="label"
            v-html="$sanitizeHtml($t('maximumUsersCount', { nb: licence.members_count }))"/>

          <CyLicenceProgressBar metric="members"/>

          <span class="users-left">
            <v-icon
              v-if="membersLeft < 1"
              color="error"
              small
              class="mr-1">
              warning
            </v-icon>
            <span v-html="$sanitizeHtml($tc('usersLeft', membersLeft, { nb: membersLeft }))"/>
          </span>
        </section>
      </div>

      <CyAlert
        v-if="!membersLeft"
        class="mt-6"
        :title="$t('noUsersLeftNotificationTitle')"
        :content="$t('noUsersLeftNotificationMessage')"
        :button-label="$t('contactUs')"
        no-bottom-margin
        @click="navigateToCycloidContactUsForm"/>
    </div>

    <div class="cy-licence-info__container">
      <div class="cy-licence-info__header">
        <h5 class="cy-licence-info__header-title">
          {{ $t('licence') }}
        </h5>

        <template v-if="!hasLicence || isExpired">
          <CyButton
            v-has-rights-to="'ActivateLicence'"
            theme="primary"
            class="mb-4"
            :loading="loading"
            @click="$toggle.applyLicenceModal(true)">
            {{ hasLicence ? $t('renewLicence') : $t('addLicence') }}
          </CyButton>
        </template>
      </div>

      <div class="cy-licence-info__content">
        <CyAlert
          v-if="!hasLicence"
          theme="warning"
          :title="$t('noActiveLicence')"
          :content="$t('noActiveLicenceMessage')"
          no-bottom-margin/>

        <CyAlert
          v-else-if="isExpired"
          theme="error"
          :title="$t('expiredLicenceNotificationTitle')"
          :content="$t('expiredLicenceNotificationMessage')"
          no-bottom-margin/>
        <div
          v-else
          class="cy-licence-info__content-row">
          <div class="cy-licence-info__content-header">
            <div class="cy-licence-info__content-status">
              <h6 class="label">
                {{ $t('forms.status') }}
              </h6>

              <p class="cy-licence-info__content-status-text">
                <span :class="['status-icon', { 'expired': isExpired }]"/>
                <span>{{ isExpired ? $t('expired') : $t('active') }}</span>
              </p>
            </div>

            <div class="cy-licence-info__content-status-expires-at">
              <h6 class="label">
                {{ $t('expiry') }}
              </h6>

              <p class="timestamp">
                {{ convertTimestampToDate(licence ? licence.expires_at : null) }}
              </p>
            </div>

            <CyTag
              v-if="!licenceVisible"
              small
              variant="default"
              element-type="button"
              @click="$toggle.licenceVisible(true)">
              {{ $t('showMore') }}
            </CyTag>
          </div>

          <CyButton
            v-has-rights-to="'ActivateLicence'"
            theme="primary"
            variant="secondary"
            sm
            class="renew-licence-btn"
            :loading="loading"
            @click="$toggle.applyLicenceModal(true)">
            {{ $t('renewLicence') }}
          </CyButton>
        </div>

        <div
          v-if="licenceVisible"
          class="details">
          <div class="details-label">
            <span>{{ $t('version') }}</span>
            <span>{{ $t('companyName') }}</span>
            <span>{{ $t('emailAddress') }}</span>
            <span>{{ $t('maximumUsers') }}</span>
          </div>
          <div class="details-value">
            <span>{{ licence.version }}</span>
            <span>{{ licence.company_name }}</span>
            <span>{{ licence.email_address }}</span>
            <span>{{ licence.members_count }}</span>
          </div>
        </div>

        <CyTag
          v-if="licenceVisible"
          small
          variant="default"
          element-type="button"
          @click="$toggle.licenceVisible(false)">
          {{ $t('showLess') }}
        </CyTag>
      </div>

      <!-- Add licence modal -->
      <CyModal
        v-if="applyLicenceModal"
        :header-title="hasLicence ? $t('renewLicence') : $t('addLicence')"
        :loading="saving"
        :cancel-btn-func="() => $toggle.applyLicenceModal(false)"
        :close-modal-on-action-click="false"
        modal-type="create">
        <div class="cy-licence-info__modal">
          <CyAlert
            theme="error"
            :content="apiErrors"/>

          <p>{{ $t('applyLicenceModalContent') }}</p>

          <v-textarea
            v-model="$v.formData.licenceToken.$model"
            :error-messages="licenceTokenFormErrors"
            spellcheck="false"
            rows="3"
            required
            dense
            class="required-field"
            @blur="$v.formData.licenceToken.$touch()">
            <template slot="label">
              <span>{{ $t('licenceKey') }}</span>
            </template>
          </v-textarea>

          <div class="mt-5">
            {{ $t('noLicence') }}
            <a
              class="cy-licence-info__modal-link"
              href="https://www.cycloid.io/contact-us"
              target="blank">
              {{ $t('contactUs') }}
            </a>
          </div>
        </div>
        <div
          class="pb-4"
          slot="modal-buttons">
          <CyButton
            class="mr-4"
            icon="close"
            theme="grey"
            variant="secondary"
            :loading="loading"
            @click="() => $toggle.applyLicenceModal(false)">
            {{ $t('forms.btnCancel') }}
          </CyButton>

          <CyButton
            :disabled="!canSubmit"
            theme="secondary"
            :loading="saving"
            @click="submit">
            {{ hasLicence ? $t('renewLicence') : $t('addLicence') }}
          </CyButton>
        </div>
      </CyModal>
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import CyLicenceProgressBar from '@/components/licence-progress-bar.vue'
import moment from 'moment' // eslint-disable-line you-dont-need-momentjs/no-import-moment
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'CyLicenceInfo',
  components: {
    CyLicenceProgressBar,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  validations: {
    formData: {
      licenceToken: { required },
    },
  },
  data: () => ({
    formData: {
      licenceToken: null,
    },
    applyLicenceModal: false,
    saving: false,
    licenceVisible: false,
  }),
  computed: {
    ...mapState('organization/licence', {
      licence: (state) => state.detail,
      apiErrors: (state) => state.errors,
    }),
    ...mapGetters('organization/billing', [
      'planName',
    ]),
    ...mapGetters('organization/licence', [
      'isExpired',
      'hasLicence',
      'membersLeft',
    ]),
    licenceTokenFormErrors () {
      const errors = []
      const { $dirty, required } = this.$v.formData.licenceToken
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))

      return errors
    },
    canSubmit () {
      const { saving, licenceTokenFormErrors, formData: { licenceToken } } = this
      return !saving && _.isEmpty(licenceTokenFormErrors) && !_.$isEmpty(licenceToken)
    },
  },
  methods: {
    ...mapActions('organization/licence', [
      'ACTIVATE_LICENCE',
    ]),
    ...mapActions('alerts', [
      'SHOW_ALERT',
    ]),
    async submit () {
      if (!this.canSubmit) return

      this.saving = true

      await this.ACTIVATE_LICENCE(this.formData)

      this.saving = false

      if (!_.$isEmpty(this.apiErrors)) return
      this.$toggle.applyLicenceModal(false)
      this.SHOW_ALERT({ type: 'success', content: this.$t('alerts.success.orgLicence.activated') })
      this.formData.licenceToken = ''
    },
    convertTimestampToDate (timestamp) {
      return !_.$isEmpty(timestamp)
        ? moment.unix(Number(timestamp)).format('DD MMM, YYYY') // eslint-disable-line you-dont-need-momentjs/format
        : ''
    },
    navigateToCycloidContactUsForm () {
      window.open('https://www.cycloid.io/contact-us', '_blank')
    },
  },
  i18n: {
    messages: {
      en: {
        active: 'Active',
        addLicence: 'Add licence',
        applyLicence: 'Apply Licence',
        applyLicenceModalContent: 'Cycloid issues you an encrypted licence key, copy this information in the input below.',
        companyName: 'Company name',
        contactUs: 'Contact us',
        emailAddress: 'Email address',
        expired: 'Expired',
        expiredLicenceNotificationMessage: 'Your licence is no longer valid. Renew the licence to continue using Cycloid.',
        expiredLicenceNotificationTitle: 'Licence expired',
        expiresAt: 'Expires at',
        expiry: 'Expiry',
        licence: 'Licence',
        licenceKey: 'Licence key',
        maximumUsers: 'Maximum users',
        maximumUsersCount: '@:maximumUsers: <strong>{nb}</strong>',
        noActiveLicence: 'No active licence',
        noActiveLicenceMessage: 'Unlock your organization by adding a licence.',
        noLicence: `Don't have a licence?`,
        noUsersLeftNotificationMessage: 'Please contact us to increase the maximum number of users of your organization.',
        noUsersLeftNotificationTitle: 'You reached maximum user capacity',
        renewLicence: 'Renew licence',
        showLess: 'Show less',
        showMore: 'Show more',
        usersLeft: 'No users left | {nb} user left | {nb} users left',
        version: 'Version',
      },
      es: {
        active: 'Activo',
        addLicence: 'Agregar licencia',
        applyLicence: 'Aplicar licencia',
        applyLicenceModalContent: 'Cycloid le emite una clave de licencia encriptada, copie esta información en la entrada a continuación.',
        companyName: 'Nombre de empresa',
        contactUs: 'Contacte con nosotros',
        emailAddress: 'Email dirección',
        expired: 'Caducado',
        expiredLicenceNotificationMessage: 'Su licencia ya no es válida. Renueve la licencia para seguir usando Cycloid.',
        expiredLicenceNotificationTitle: 'Licencia vencida',
        expiresAt: 'Caduca a las',
        expiry: 'Expiración',
        licence: 'Licencia',
        licenceKey: 'Clave de licencia',
        maximumUsers: 'Usuarios máximos',
        maximumUsersCount: '@:maximumUsers: <strong>{nb}</strong>',
        noActiveLicence: 'Sin licencia activa',
        noActiveLicenceMessage: 'Desbloquee su organización agregando una licencia.',
        noLicence: '¿No tienes licencia?',
        noUsersLeftNotificationMessage: 'Comuníquese con nosotros para aumentar el número máximo de usuarios de su organización.',
        noUsersLeftNotificationTitle: 'Ha alcanzado la capacidad máxima de usuarios',
        renewLicence: 'Renovar licencia',
        showLess: 'Muestra menos',
        showMore: 'Mostrar más',
        usersLeft: 'No quedan usuarios | Queda {nb} usuario | Quedan {nb} usuarios',
        version: 'Versión',
      },
      fr: {
        active: 'Actif',
        addLicence: 'Ajouter une licence',
        applyLicence: 'Appliquer la licence',
        applyLicenceModalContent: `Cycloid vous délivre une clé de licence cryptée, copiez ces informations dans l'entrée ci-dessous.`,
        companyName: 'Nom de la compagnie',
        contactUs: 'Nous contacter',
        emailAddress: 'Email adresse',
        expired: 'Expiré',
        expiredLicenceNotificationMessage: `Votre licence n'est plus valide. Renouvelez le licence pour continuer à utiliser Cycloid.`,
        expiredLicenceNotificationTitle: 'Licence expirée',
        expiresAt: 'Expire à',
        expiry: 'Expiration',
        licence: 'Licence',
        licenceKey: 'Clé de licence',
        maximumUsers: 'Utilisateurs maximum',
        maximumUsersCount: '@:maximumUsers: <strong>{nb}</strong>',
        noActiveLicence: 'Pas de licence active',
        noActiveLicenceMessage: 'Déverrouillez votre organisation en ajoutant une licence.',
        noLicence: `Vous n'avez pas de licence?`,
        noUsersLeftNotificationMessage: `Veuillez nous contacter pour augmenter le nombre maximum d'utilisateurs de votre organisation.`,
        noUsersLeftNotificationTitle: `Vous avez atteint la capacité maximale de l'utilisateur`,
        renewLicence: 'Renouveler la licence',
        showLess: 'Montrer moins',
        showMore: 'Montre plus',
        usersLeft: 'Aucun utilisateur restant | {nb} utilisateur restant | {nb} utilisateurs restants',
        version: 'Version',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.cy-licence-info {
  padding: 0;

  &__container {
    padding: 16px 24px;
  }

  .label {
    color: cy-get-color("grey", "dark-1");
    font-size: $font-size-sm;
    font-weight: $font-weight-default;

    ::v-deep strong {
      color: cy-get-color("primary");
    }
  }

  &__members-count {
    padding: 16px 24px;
    border-bottom: 1px solid cy-get-color("grey");

    ::v-deep .users-left {
      color: cy-get-color("primary");
      font-size: $font-size-sm;
    }
  }

  &__header {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
  }

  &__header-title {
    color: cy-get-color("grey", "dark-2");
    font-size: $font-size-sm;
    font-weight: $font-weight-bolder;
    letter-spacing: 0.05em;
    line-height: 14.06px;
    text-transform: uppercase;
  }

  &__content-row {
    display: flex;
    align-items: center;
  }

  &__content-header {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    height: 40px;
    margin-top: 16px;
  }

  &__content-status-label {
    color: cy-get-color("grey", "dark-1");
    font-size: $font-size-default;
    font-weight: $font-weight-default;
  }

  &__content-status-text {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 48px;
    margin-bottom: 0;
    color: cy-get-color("primary");
    font-size: $font-size-sm;
    line-height: 150%;

    .status-icon {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 6px;
      min-width: 6px;
      height: 6px;
      margin-right: 8px;
      border-radius: 50%;
      background-color: cy-get-color("success");
      color: cy-get-color("primary");
    }

    .expired {
      background-color: cy-get-color("error");
      color: cy-get-color("error");
    }
  }

  &__content-status-expires-at {
    margin-right: 20px;
    margin-left: 24px;
    color: cy-get-color("grey", "dark-1");
    justify-self: flex-start;

    > {
      font-weight: $font-weight-default;
    }

    .timestamp {
      margin-bottom: 0;
      color: cy-get-color("primary");
      font-family: $font-family-default;
      font-size: $font-size-sm;
      font-style: normal;
      line-height: 150%;
    }
  }

  .renew-licence-btn {
    margin-left: auto;
  }

  .details {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: flex-start;
    min-width: 402px;
    margin-top: 24px;
    margin-bottom: 16px;
  }

  .details-label {
    display: flex;
    flex-direction: column;
    margin-right: 24px;

    > * {
      margin-top: 4px;
      color: cy-get-color("grey", "dark-1");
      font-size: $font-size-sm;
      font-weight: $font-weight-default;
    }
  }

  .details-value {
    display: flex;
    flex-direction: column;

    > * {
      margin-top: 4px;
      color: cy-get-color("primary");
      font-size: $font-size-sm;
    }
  }

  &__modal {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 15px;

    .v-textarea {
      width: 100%;
      margin-top: 36px;

      ::v-deep .v-label {
        font-size: $font-size-sm;
        line-height: 17px;
      }
    }
  }

  &__modal-link {
    color: cy-get-color("secondary");
    font-weight: $font-weight-bolder;
    text-transform: lowercase;
  }
}
</style>
