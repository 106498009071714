<template>
  <div>
    <CyDetails
      :item-id="orgCanonical"
      :on-save="onSave"
      :on-cancel="onCancel"
      :can-cancel="canCancel"
      :can-save="canSave"
      :loading="!orgCanonical"
      :saving="saving"
      :is-read-only="!hasActiveBillingPlan"
      hide-delete
      @keyup.enter.native="onEnter">
      <template slot="details_formTopFullWidth">
        <CyAlert
          theme="error"
          :content="errors"/>
      </template>

      <div
        class="org-settings-content"
        slot="details_formFullWidth">
        <!-- Details -->
        <section
          v-if="showSection.details"
          class="section-details">
          <h2 class="h5 section-details__title">
            {{ $t('Details') }}
          </h2>

          <div class="section-details__content">
            <v-text-field
              v-model="$v.formContent.name.$model"
              :autofocus="$isCreationRoute"
              :label="$t('forms.fieldOrgName')"
              :error-messages="nameErrors"
              :disabled="!canEdit"
              :readonly="!canEdit"
              :loading="loading"
              required
              class="required-field"/>

            <!-- Metadata -->
            <v-card
              elevation="0"
              class="panel panel--grey panel--with-border pa-4">
              <v-progress-linear
                v-if="loading"
                indeterminate/>
              <template v-else>
                <h3 class="cy-headline darkgrey--text mb-4">
                  {{ $t('untranslated.metadata') }}
                </h3>

                <p class="mb-2">
                  <strong>{{ $t('untranslated.apiUrl') }}:</strong>
                  {{ apiUrl }}
                  <CyCopyBtn
                    :copy-value="apiUrl"
                    small/>
                </p>

                <p class="mb-2">
                  <strong>{{ _.lowerFirst($t('untranslated.canonical')) }}:</strong>
                  {{ formContent.canonical }}
                </p>

                <p class="mb-0">
                  <strong>{{ $t('untranslated.ciTeamName') }}:</strong>
                  {{ formContent.ciTeamName }}
                  <CyCopyBtn
                    :copy-value="formContent.ciTeamName"
                    small/>
                </p>
              </template>
            </v-card>
          </div>
        </section>

        <!-- Plan -->
        <section class="section-plan">
          <div class="section-plan__title">
            <h2 class="h5">
              {{ $t('untranslated.plan') }}
            </h2>
            <p v-if="showPlan.selection">
              {{ $t('section.plan.subtitle.getStarted') }}
            </p>
          </div>

          <div class="section-plan__content">
            <CyPlansSelection
              v-if="showPlan.selection"
              :loading="loading"/>
            <CyPlanInfo
              v-else-if="showPlan.info"
              :loading="loading"/>
            <CySubscriptionInfo
              v-else-if="organization.subscription"
              :loading="loading"/>
            <CyLicenceInfo
              v-else-if="showPlan.licence"
              :loading="loading"/>
          </div>
        </section>

        <!-- Authentication -->
        <section
          v-if="showSection.authentication"
          class="section-authentication">
          <div class="section-authentication__title">
            <h2 class="h5">
              {{ $t('section.authentication.title') }}
            </h2>
            <p>
              {{ $t('section.authentication.subtitle') }}
            </p>
          </div>

          <div class="section-authentication__content">
            <v-radio-group
              v-model="formContent.mfaEnabled"
              class="mt-0">
              <template #label>
                {{ $t('section.authentication.requireMFA') }}
              </template>
              <CyInputsRadioCard :value="false">
                <div slot="label">
                  <strong class="d-block">Never</strong>
                  <p class="mb-0">
                    {{ $t('section.authentication.choices.disabled') }}
                  </p>
                </div>
              </CyInputsRadioCard>
              <CyInputsRadioCard :value="true">
                <div slot="label">
                  <strong class="d-block">Always</strong>
                  <p class="mb-0">
                    {{ $t('section.authentication.choices.enabled') }}
                  </p>
                </div>
              </CyInputsRadioCard>
            </v-radio-group>

            <span v-html="$tc('section.authentication.information', membersWithoutMFA, { nb: membersWithoutMFA })"/>
            <router-link
              :to="{ name: 'members' }"
              class="cy-link">
              {{ $t('section.authentication.linkReview') }}
            </router-link>
          </div>
        </section>

        <!-- Features -->
        <section
          v-if="showSection.features"
          class="section-features">
          <div class="section-features__title">
            <h2 class="h5">
              {{ $t('section.features.title') }}
            </h2>
          </div>

          <div class="section-features__content">
            <CyFeatureToggle
              v-if="$showFeature.sso"
              title="Custom login"
              subtitle="// DEV ONLY // Placeholder for SSO feature"
              :disabled="!canEdit"
              :readonly="!canEdit"
              :enabled="false"
              :loading="loading"/>

            <CyFeatureToggle
              :title="$t('section.features.quotas.title')"
              :subtitle="$t('section.features.quotas.subtitle')"
              :disabled="!canEdit"
              :readonly="!canEdit"
              :enabled.sync="formContent.quotas"
              :loading="loading">
              <template #enabledMessage>
                <CyAlert
                  :title="$t('section.features.quotas.message.title')"
                  :content="$t('section.features.quotas.message.content', { docLink: $docLinks.quotas.index })"
                  no-bottom-margin/>
              </template>
            </CyFeatureToggle>
          </div>
        </section>

        <!-- Terraform -->
        <section
          v-if="showSection.terraform"
          v-has-rights-to="'GetExternalBackends'"
          class="section-terraform">
          <h2 class="h5 section-terraform__title">
            {{ $t('untranslated.terraform') }}
          </h2>

          <v-card
            outlined
            class="cy-card section-terraform__content">
            <h3 class="content__title">
              {{ $t('externalBackends.RemoteBackend') }}
            </h3>

            <i18n
              path="externalBackends.configureHint"
              tag="p"
              class="content__subtitle">
              <template #files>
                <a
                  :href="$docLinks.terraform.state"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="cy-link"
                  data-cy="doc-link">{{ $t('externalBackends.terraformStateFiles') }}</a>
              </template>
            </i18n>

            <CyButton
              class="content__button"
              :to="{
                name: 'orgSettingsTerraform',
                params: { orgCanonical },
              }"
              variant="secondary"
              theme="primary"
              sm
              :loading="loading">
              {{ canEdit ? $t('configure') : $t('Details') }}
            </CyButton>
          </v-card>
        </section>

        <!-- Appearance -->
        <section
          v-if="showSection.appearance"
          class="section-appearance">
          <div class="section-appearance__title">
            <h2 class="h5">
              {{ $t('Appearance') }}
              <CyBetaTag
                v-if="$isBetaFeature.whiteLabel"
                direction="right"
                class="ml-1"/>
            </h2>
            <p>{{ $t('section.appearance.subtitle') }}</p>
          </div>

          <div class="section-appearance__content">
            <CyAppearanceSelection
              v-model="$v.activeAppearance.$model"
              :loading="loading"/>
            <CyAlert
              class="mt-4"
              :title="$t('section.appearance.message.title')"
              :content="$t('section.appearance.message.content')"/>
            <v-checkbox
              v-model="$v.formContent.canChildrenCreateAppearance.$model"
              :disabled="!$cycloid.permissions.canDisplay('UpdateOrg')"
              :readonly="!$cycloid.permissions.canDisplay('UpdateOrg')"
              :ripple="$cycloid.permissions.canDisplay('UpdateOrg')"
              :label="$t('section.appearance.checkboxLabel')"/>
          </div>
        </section>

        <!-- Danger zone -->
        <CyDangerZone
          v-if="!loading"
          v-has-rights-to="'DeleteOrg'"
          class="section-delete"
          :entity-name="$data.$originalData.formContent.name"
          :entity-type="$t('organization')"
          :delete-button-text="$t('section.dangerZone.deleteOrganization')"
          :delete-text="$t('section.dangerZone.canDelete.body')"
          :delete-title="$t('section.dangerZone.deleteOrganization')"
          @delete="onDeleteConfirm">
          <template #confirmText>
            <!-- Cannot delete org -->
            <template v-if="cannotDeleteOrg">
              <CyAlert
                theme="warning"
                :title="$t('section.dangerZone.cannotDelete.title')">
                <p
                  class="mb-1"
                  v-html="$sanitizeHtml($tc('section.dangerZone.cannotDelete.body', childOrgs.length, { nb: childOrgs.length } ))"/>
                <router-link
                  class="cy-link--warning"
                  :to="{ name: 'organizations' }">
                  {{ $t('section.dangerZone.cannotDelete.seeOrgList') }}
                </router-link>
              </CyAlert>
            </template>

            <!-- Can delete org -->
            <template v-else>
              <p>{{ $t('section.dangerZone.canDelete.confirmBody') }}</p>
              <h3>{{ $data.$originalData.formContent.name }}</h3>
            </template>
          </template>
        </CyDangerZone>
      </div>
    </CyDetails>

    <CyBeforeExitModal
      v-if="showBeforeExitModal"
      :is-saving="saving"
      @stay-on-page="stayOnPage"
      @exit-without-saving="exitWithoutSaving"
      @save-and-exit="saveAndExit"/>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters, mapState } from 'vuex'
import CyAppearanceSelection from '@/components/appearance/selection.vue'
import CyBeforeExitModal from '@/components/before-exit-modal.vue'
import CyCopyBtn from '@/components/copy-btn.vue'
import CyDangerZone from '@/components/danger-zone.vue'
import CyDetails from '@/components/details.vue'
import CyFeatureToggle from '@/components/feature-toggle.vue'
import CyInputsRadioCard from '@/components/inputs/radio-card.vue'
import CyLicenceInfo from '@/components/licence-info.vue'
import CyPlanInfo from '@/components/plan-info.vue'
import CyPlansSelection from '@/components/plans-selection.vue'
import CySubscriptionInfo from '@/components/subscription-info.vue'
import { pagination as createAPIPage } from '@/utils/api'
import { constructBreadcrumb } from '@/utils/helpers'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'CyPageOrgSettings',
  components: {
    CyAppearanceSelection,
    CyBeforeExitModal,
    CyCopyBtn,
    CyDangerZone,
    CyDetails,
    CyFeatureToggle,
    CyInputsRadioCard,
    CyLicenceInfo,
    CyPlanInfo,
    CyPlansSelection,
    CySubscriptionInfo,
  },
  breadcrumb () {
    return constructBreadcrumb(this.$options.name, this.$t('routes.orgSettings'))
  },
  beforeRouteLeave (to, _from, next) {
    const userCanLeave = () => !_.isEmpty(this.to) || !this.canCancel

    if (userCanLeave()) return next()

    this.to = to
    this.$toggle.showBeforeExitModal(true)
  },
  validations: {
    formContent: {
      name: { required },
      quotas: {},
      canChildrenCreateAppearance: {},
      mfa_enabled: {},
    },
    activeAppearance: {},
  },
  data: () => ({
    formContent: {
      name: '',
      quotas: false,
      canChildrenCreateAppearance: false,
      mfa_enabled: false,
    },
    activeAppearance: '',
    loading: true,
    saving: false,
    showBeforeExitModal: false,
    to: null,
  }),
  computed: {
    ...mapState({
      appearanceErrors: (state) => state.organization.appearance.errors,
      appearances: (state) => state.organization.available.appearances,
      childOrgs: (state) => state.organization.available.childOrgs,
      organization: (state) => state.organization.detail,
      orgErrors: (state) => state.organization.errors.organization,
      apiUrl: () => process.env.VUE_APP_API_URL,
    }),
    ...mapGetters([
      'orgMembers',
    ]),
    ...mapGetters([
      'appearance',
    ]),
    ...mapGetters('organization/billing', [
      'hasActiveBillingPlan',
      'isFreeTrial',
      'requirePayment',
      'requirePlan',
    ]),
    errors () {
      return [
        ...this.orgErrors,
        ...this.appearanceErrors,
      ]
    },
    showPlan () {
      const { requirePayment, requirePlan, isFreeTrial } = this
      const showInfo = requirePayment || isFreeTrial
      if (requirePlan) return { selection: true }
      if (showInfo) return { info: true }
      return { licence: true }
    },
    showAppearance () {
      const { canDisplay } = this.$cycloid.permissions
      const { canCreateAppearance } = this.formContent
      const hasAdequatePermissions = canDisplay('ListAppearances')
      return _.every([this.$showFeature.whiteLabel, canCreateAppearance, hasAdequatePermissions])
    },
    showSection () {
      const { canEdit, hasActiveBillingPlan, showAppearance } = this
      return {
        appearance: hasActiveBillingPlan && showAppearance,
        authentication: hasActiveBillingPlan,
        details: hasActiveBillingPlan,
        features: hasActiveBillingPlan && canEdit,
        metadata: hasActiveBillingPlan,
        terraform: hasActiveBillingPlan && canEdit,
      }
    },
    nameErrors () {
      const errors = []
      const { $dirty, required } = this.$v.formContent.name
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      return errors
    },
    canCancel () {
      return !this.loading && _.some([
        this.$hasDataChanged('formContent'),
        this.$hasDataChanged('activeAppearance'),
      ])
    },
    canEdit () {
      return _.every([
        !this.loading,
        this.hasActiveBillingPlan,
        this.$cycloid.permissions.canDisplay('UpdateOrg'),
      ])
    },
    canSave () {
      return _.every([
        this.canCancel,
        !this.loading,
        !this.$v.formContent.$invalid,
        !this.$v.activeAppearance.$invalid,
      ])
    },
    cannotDeleteOrg () {
      return this.childOrgs.length > 0
    },
    membersWithoutMFA () {
      return _.filter(this.orgMembers, (member) => !member.mfa_enabled).length
    },
  },
  watch: {
    orgCanonical: {
      async handler (orgCanonical) {
        this.$toggle.loading(true)
        if (this.$route.params.orgCanonical !== orgCanonical) {
          this.$router.replace({ name: 'orgSettings', params: { orgCanonical } })
            .catch(() => { /* silenced */ })
        }
        await Promise.all([
          this.GET_ORGANIZATION(orgCanonical),
          this.fetchChildOrgs(),
          this.fetchAppearances(),
          this.fetchMembersOrgs(),
        ])
        this.setFormContent()
        this.$toggle.loading(false)
      },
      immediate: true,
    },
    hasActiveBillingPlan: {
      async handler (newVal) {
        if (!newVal) return
        this.$toggle.loading(true)
        await this.fetchAppearances()
        this.setFormContent()
        this.$toggle.loading(false)
      },
    },
    '$showFeature.whiteLabel': {
      async handler (newVal) {
        if (!newVal) return
        this.$toggle.loading(true)
        await this.fetchAppearances()
        this.setFormContent()
        this.$toggle.loading(false)
      },
    },
  },
  methods: {
    ...mapActions([
      'FETCH_APP_APPEARANCE',
    ]),
    ...mapActions('organization', [
      'DELETE_ORGANIZATION',
      'GET_ORGANIZATION',
      'UPDATE_ORGANIZATION',
      'FETCH_AVAILABLE',
    ]),
    ...mapActions('organization/appearance', [
      'UPDATE_APPEARANCE',
    ]),
    ...mapMutations('organization', [
      'CLEAR_ERRORS',
    ]),
    async fetchAppearances () {
      if (!this.$cycloid.permissions.canDisplay('ListAppearances')) return
      await this.FETCH_AVAILABLE({ keyPath: 'appearances' })
    },
    async fetchChildOrgs () {
      if (!this.$cycloid.permissions.canDisplay('GetChildren')) return
      await this.FETCH_AVAILABLE({ keyPath: 'childOrgs' })
    },
    async fetchMembersOrgs () {
      if (!this.$cycloid.permissions.canDisplay('GetOrgMembers')) return
      await this.FETCH_AVAILABLE({ keyPath: 'members' })
    },
    setFormContent () {
      const organization = _.cloneDeep(this.organization)
      this.formContent = _.$camelCaseKeys(organization)
      this.activeAppearance = this.appearance?.canonical
      this.$setOriginalData()
    },
    onCancel () {
      this.$resetData('formContent')
      this.$resetData('activeAppearance')
      this.$v.$reset()
      this.CLEAR_ERRORS('organization')
    },
    async onSave () {
      this.$toggle.saving(true)
      const {
        formContent: { name, quotas, canChildrenCreateAppearance, mfaEnabled },
        $hasDataChanged, orgName,
      } = this
      const organization = _.$snakeCaseKeys({ ...this.organization, name, quotas, canChildrenCreateAppearance, mfaEnabled })

      const onlyAppearanceChanged = !$hasDataChanged('formContent') && $hasDataChanged('activeAppearance')

      if (!onlyAppearanceChanged) await this.UPDATE_ORGANIZATION({ organization })
      else {
        const successMessage = this.$t('alerts.success.organization.updated', { orgName })
        const appearance = _.find(this.appearances, ['canonical', this.activeAppearance])
        appearance.is_active = true
        await this.UPDATE_APPEARANCE({ appearance, successMessage })
        if (_.isEmpty(this.appearanceErrors) && !this.to) {
          await this.FETCH_APP_APPEARANCE(this.orgCanonical)
          await this.FETCH_AVAILABLE({ keyPath: 'appearances' })
        }
      }

      this.$toggle.saving(false)

      if (_.isEmpty(this.errors) && !this.to) this.setFormContent()
    },
    async onEnter () {
      if (this.canSave) await this.onSave()
    },
    async onDeleteConfirm () {
      const { $router, organization } = this
      const reqPage = createAPIPage(1, 100)

      await this.DELETE_ORGANIZATION({ reqPage, $router, organization })
    },
    stayOnPage () {
      this.$toggle.showBeforeExitModal(false)
      this.to = null
    },
    exitWithoutSaving () {
      this.$toggle.showBeforeExitModal(false)
      this.$router.push(this.to)
    },
    async saveAndExit () {
      await this.onSave()
      this.$toggle.showBeforeExitModal(false)
      this.$router.push(this.to)
    },
  },
  i18n: {
    messages: {
      en: {
        title: '@:Organization @:routes.orgSettings',
        section: {
          appearance: {
            subtitle: 'Choose what Cycloid looks like for your users. Use your own brand colors and logos to display throughout the application.',
            message: {
              title: '@:Appearance inheritance.',
              content: 'Child @:organizations will inherit any @:appearance this @:organization has active. Any changes made will be reflected in child @:organizations. <a href="#">@:ReadMore</a>',
            },
            checkboxLabel: 'Allow child @:organizations to choose their own @:appearance',
          },
          authentication: {
            title: 'Authentication',
            subtitle: 'Manage your organization authentication settings',
            requireMFA: 'Require Multi-Factor Authentication',
            choices: {
              disabled: 'Users are not required to use additional factors to login.',
              enabled: 'Users are required additional factors to login.',
            },
            information: `There is <strong>{nb} member not using Multi-Factor Authentication</strong> for their account. When requiring MFA, members will be asked to enable it before they can access this organization.
            | There are <strong>{nb} members not using Multi-Factor Authentication</strong> for their accounts. When requiring MFA, members will be asked to enable it before they can access this organization.`,
            linkReview: 'Review members MFA status',
          },
          dangerZone: {
            deleteOrganization: 'Delete @:organization',
            canDelete: {
              body: 'Deleting this @:organization is a permanent action that cannot be undone. Resources created through pipelines will not be deleted automatically.',
              confirmBody: 'Are you sure that you want to delete this @:organization?',
            },
            cannotDelete: {
              title: `Can't delete this @:organization`,
              body: 'This organization has <strong>{nb}</strong> sub-organization that needs to be deleted manually first. | This organization has <strong>{nb}</strong> sub-organizations that need to be deleted manually first.',
              seeOrgList: 'See the organization list',
            },
          },
          features: {
            title: 'Features',
            quotas: {
              title: '@:Quotas',
              subtitle: 'Control how many resources your teams can deploy.',
              message: {
                title: 'Quotas are currently only compatible with bare metal infrastructure.',
                content: 'This feature cannot be disabled until all quotas are deleted. Check out how to <a href={docLink}>get started with quotas</a>.',
              },
            },
          },
          plan: {
            subtitle: {
              getStarted: `Let's get you started using Cycloid.`,
            },
          },
        },
      },
      es: {
        title: '@:Organization @:routes.orgSettings',
        section: {
          appearance: {
            subtitle: 'Elija cómo se ve Cycloid para sus usuarios. Utilice los colores y logotipos de su propia marca para mostrarlos en toda la aplicación.',
            message: {
              title: 'Herencia de @:appearance',
              content: 'Las @:organizations secundarias heredarán cualquier @:appearance que esta @:organization tenga activa. Cualquier cambio realizado se reflejará en las @:organizations infantiles. <a href="#">@:ReadMore</a>',
            },
            checkboxLabel: 'Permitir que las @:organizations hijas elijan su propia @:appearance',
          },
          authentication: {
            title: 'Autenticación',
            subtitle: 'Administrar la configuración de autenticación de su organización',
            requireMFA: 'Requerir Autenticación Multifactor',
            choices: {
              disabled: 'Los usuarios no están obligados a utilizar factores adicionales para iniciar sesión.',
              enabled: 'Se requieren factores adicionales para que los usuarios inicien sesión.',
            },
            information: `Hay <strong>{nb} miembro que no utiliza la Autenticación Multifactor</strong> para su cuenta. Cuando se requiera MFA, se pedirá a los miembros que lo activen antes de poder acceder a esta organización.
            | Hay <strong>{nb} miembros que no utilizan la Autenticación Multifactor</strong> para sus cuentas. Cuando se requiera MFA, se pedirá a los miembros que lo activen antes de poder acceder a esta organización.`,
            linkReview: 'Revisar estado de MFA de los miembros',
          },
          dangerZone: {
            deleteOrganization: 'Borrar @:organization',
            canDelete: {
              body: 'Eliminar esta @:organization es una acción permanente que no se puede deshacer. Los recursos creados a través de las pipelines no se eliminarán automáticamente.',
              confirmBody: '¿Está seguro de querer borrar esta @:organization?',
            },
            cannotDelete: {
              title: 'No se puede borrar esta @:organization',
              body: 'Esta organización tiene <strong>{nb}</strong> sub-organización que necesita ser eliminada manualmente primero. | Esta organización tiene <strong>{nb}</strong> sub-organizaciones que necesitan ser eliminadas manualmente primero.',
              seeOrgList: 'Ver el listado de organizaciones',
            },
          },
          features: {
            title: 'Características',
            quotas: {
              title: '@:Quotas',
              subtitle: 'Controle la cantidad de recursos que pueden implementar sus equipos.',
              message: {
                title: 'Actualmente, las quotas solo son compatibles con la infraestructura bare metal.',
                content: 'Esta función no se puede desactivar hasta que se eliminen todas las quotas. Vea cómo <a href={docLink}>comenzar con las quotas</a>.',
              },
            },
          },
          plan: {
            subtitle: {
              getStarted: 'Comencemos a usar Cycloid.',
            },
          },
        },
      },
      fr: {
        title: '@:Organization @:routes.orgSettings',
        section: {
          appearance: {
            subtitle: `Choisissez à quoi ressemblera Cycloid pour vos utilisateurs. Utilisez les couleurs et les logos de votre propre marque à afficher dans toute l'application.`,
            message: {
              title: `Héritage de l'apparence.`,
              content: `Les @:organisations d'enfants hériteront de toute @:appearance active de cette @:organization. Toute modification apportée sera répercutée sur les @:organizations d'enfants. <a href="#">@:ReadMore</a>`,
            },
            checkboxLabel: 'Autoriser les @:organisations filles à choisir leur propre @:appearance',
          },
          authentication: {
            title: 'Authentification',
            subtitle: `Gérer les paramètres d'authentification de votre organisation`,
            requireMFA: 'Exiger une Authentification Multifactorielle',
            choices: {
              disabled: `Les utilisateurs ne sont pas obligés d'utiliser des facteurs supplémentaires pour se connecter.`,
              enabled: 'Les utilisateurs doivent utiliser des facteurs supplémentaires pour se connecter.',
            },
            information: `Il y a <strong>{nb} membre n'utilisant pas l'authentification multifactorielle</strong> pour leur compte. Lorsqu'une MFA est requise, les membres devront l'activer avant de pouvoir accéder à cette organisation.
            | Il y a <strong>{nb} membres n'utilisant pas l'authentification multifactorielle</strong> pour leurs comptes. Lorsqu'une MFA est requise, les membres devront l'activer avant de pouvoir accéder à cette organisation.`,
            linkReview: `Vérifier l'état de la MFA des membres`,
          },
          dangerZone: {
            deleteOrganization: `Supprimer l'organisation`,
            canDelete: {
              body: 'La suppression de cette @:organization est une action permanente qui ne peut pas être annulée. Les ressources créées via des pipelines ne serontpas supprimées automatiquement.',
              confirmBody: 'Êtes vous sûr de vouloir supprimer cette @:organization ?',
            },
            cannotDelete: {
              title: 'Impossible de supprimer cette @:organization',
              body: `Cette organisation a <strong> {nb} </strong> sous-organisation qui doit d'abord être supprimée manuellement. | Cette organisation a <strong> {nb} </strong> sous-organisations qui doivent d'abord être supprimées manuellement.`,
              seeOrgList: 'Voir la liste des organisations',
            },
          },
          features: {
            title: 'Fonctionalités',
            quotas: {
              title: '@:Quotas',
              subtitle: 'Contrôlez combien de ressources vos équipes peuvent déployer.',
              message: {
                title: `Les quotas ne sont actuellement compatibles qu'avec une infrastructure bare metal.`,
                content: `Cette fonctionnalité ne peut pas être désactivée tant que tous les quotas n'ont pas été supprimés. Découvrez comment <a href={docLink}>démarrer avec les quotas</a>.`,
              },
            },
          },
          plan: {
            subtitle: {
              getStarted: 'Commencez à utiliser Cycloid.',
            },
          },
        },
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.org-settings-content {
  display: grid;
  grid-template-areas:
    "details        metadata"
    "plan           metadata"
    "authentication metadata"
    "features       metadata"
    "terraform      metadata"
    "appearance     metadata"
    "delete         metadata";
  gap: 0 32px;
  grid-template-columns: 1fr 336px;

  @media screen and (width <= 1250px) {
    grid-template-areas:
      "details       "
      "metadata      "
      "plan          "
      "authentication"
      "features      "
      "terraform     "
      "appearance    "
      "delete        ";
    grid-template-columns: 1fr;

    .section-metadata {
      border-bottom: 1px solid cy-get-color("grey", "light-1");
    }
  }

  .section-delete {
    grid-area: delete;
    border-bottom: none;
  }

  > [class^="section-"],
  .section-delete {
    display: grid;
    grid-template-areas: "title content";
    grid-template-columns: 336px 1fr;
    padding: 32px 0;

    &:first-child {
      padding-top: 8px;
    }

    &:not(:last-child, .section-metadata) {
      border-bottom: 1px solid cy-get-color("grey", "light-1");
    }

    > [class$="__title"],
    ::v-deep .danger-zone__title {
      grid-area: title;
      margin-right: 32px;

      &.h5,
      .h5 {
        padding-bottom: 16px;
      }

      @media screen and (width <= 880px) {
        margin-right: 0;
      }
    }

    > [class^="section-"][class$="__content"] {
      grid-area: content;
      align-self: flex-start;
    }

    @media screen and (width <= 1340px) {
      grid-template-columns: 250px 1fr;
    }

    @media screen and (width <= 880px) {
      grid-template-areas:
        "title"
        "content";
      grid-template-columns: 1fr;
    }
  }

  .section-metadata {
    grid-area: metadata;
    border-color: transparent;

    @media screen and (width <= 1340px) {
      border-color: cy-get-color("grey", "light-1");
    }

    @media screen and (width >= 1250px) {
      display: flex;
      flex-direction: column;

      &__title {
        grid-area: null;
      }

      &__content {
        grid-area: null;
      }
    }
  }

  .section-details {
    grid-area: details;
  }

  .section-plan {
    grid-area: plan;
  }

  .section-authentication {
    grid-area: authentication;
  }

  .section-features {
    grid-area: features;
  }

  .section-terraform {
    grid-area: terraform;

    &__content {
      display: grid;
      grid-template-areas:
        "title    button"
        "subtitle button";
      gap: 0 16px;
      grid-template-columns: 1fr auto;
      padding: 16px 24px;

      .content {
        &__title {
          grid-area: title;
          margin-bottom: 8px;
          color: cy-get-color("grey", "dark-2");
          font-size: $font-size-sm;
          font-weight: $font-weight-bolder;
          letter-spacing: 0.05em;
          line-height: 14.06px;
          text-transform: uppercase;
        }

        &__subtitle {
          grid-area: subtitle;
          margin-bottom: 0;
          color: cy-get-color("grey", "dark-2");
        }

        &__button {
          grid-area: button;
          align-self: center;
        }
      }
    }
  }

  .section-appearance {
    grid-area: appearance;
  }
}
</style>
