<template>
  <CyModal
    :header-title="$t('modalTitle')"
    :cancel-btn-func="onCancel"
    modal-type="warning"
    small>
    <template slot="default">
      <p class="ma-0">
        {{ $t('modalMessage.line1') }}
      </p>
      <p class="ma-0">
        {{ $t('modalMessage.line2') }}
      </p>
    </template>

    <template slot="modal-buttons">
      <div class="d-flex justify-space-between width-100">
        <CyButton
          :disabled="isSaving"
          icon="close"
          theme="primary"
          variant="tertiary"
          class="font-weight-bold"
          @click="onCancel">
          {{ $t('stayOnPage') }}
        </CyButton>
        <div>
          <CyButton
            :disabled="isSaving"
            icon="input"
            theme="error"
            variant="secondary"
            @click="$emit('exit-without-saving')">
            {{ $t('exitWithoutSaving') }}
          </CyButton>

          <CyButton
            icon="check"
            theme="warning"
            variant="primary"
            :loading="isSaving"
            @click="$emit('save-and-exit')">
            {{ $t('saveAndExit') }}
          </CyButton>
        </div>
      </div>
    </template>
  </CyModal>
</template>

<script>
export default {
  name: 'CyBeforeExitModal',
  props: {
    isSaving: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    onCancel () {
      this.$emit('stay-on-page')
    },
  },
  i18n: {
    messages: {
      en: {
        exitWithoutSaving: 'Exit without saving',
        modalMessage: {
          line1: 'You have unsaved changes.',
          line2: 'Would you like to save them before you leave?',
        },
        modalTitle: 'Unsaved changes detected',
        saveAndExit: 'Save and exit',
        stayOnPage: 'Stay on page',
      },
      es: {
        exitWithoutSaving: 'Salir sin guardar',
        modalMessage: {
          line1: 'Tiene cambios sin guardar.',
          line2: '¿Desea guardarlos antes de irse?',
        },
        modalTitle: 'Cambios no guardados detectados',
        saveAndExit: 'Guardar y Salir',
        stayOnPage: 'Quedate en la pagina',
      },
      fr: {
        exitWithoutSaving: 'Quitter sans enregistrer',
        modalMessage: {
          line1: 'Vous avez des modifications non enregistrées.',
          line2: 'Souhaitez-vous les enregistrer avant votre départ?',
        },
        modalTitle: 'Changements non enregistrés détectés',
        saveAndExit: 'Enregistrer et quitter',
        stayOnPage: 'Rester sur la page',
      },
    },
  },
}
</script>
